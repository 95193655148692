function initSentry(data) {

    Sentry.init({
        dsn: data.dsn,
        environment: data.env,
        debug: false,
        replaysSessionSampleRate: 1.0,
        integrations: [
            new Sentry.Replay({
                maskAllText: false,
                maskAllInputs: false,
                blockAllMedia: false,
                stickySession: true,
            })
        ],
    });

    Sentry.setUser(data.user);

    if (Array.isArray(data.tags)) {
        data.tags.forEach(tag => {
            Sentry.setTag(tag[0], tag[1]);
        });
    }
}

if (window.sentryData) {
    initSentry(window.sentryData);
}
