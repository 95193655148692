document.addEventListener('livewire:init', function () {
    // Initialize Google reCAPTCHA
    window.renderReCaptcha();

    // Initialize tooltips
    document.querySelectorAll('[data-tooltip-target]').forEach(el => {
        let id = el.getAttribute('data-tooltip-target');
        initTooltip(id);
    });
});

document.addEventListener('livewire:update', function () {
    // Reinitialize Google reCAPTCHA
    window.renderReCaptcha();

    // Reinitialize tooltips
    document.querySelectorAll('[data-tooltip-target]').forEach(el => {
        let id = el.getAttribute('data-tooltip-target');
        initTooltip(id);
    });
});

window.renderReCaptcha = function () {
    if (typeof grecaptcha !== 'undefined' && grecaptcha && typeof grecaptcha.render === 'function') {
        const recaptchaContainer = document.getElementById('recaptcha-container');
        if (recaptchaContainer && recaptchaContainer.innerHTML === '') {
            const recaptchaSiteKey = document.querySelector('meta[name="recaptcha-site-key"]').getAttribute('content');
            const recaptchaElement = document.createElement('div');
            recaptchaElement.id = 'recaptcha-element';
            recaptchaContainer.appendChild(recaptchaElement);
            grecaptcha.render('recaptcha-element', {
                'sitekey': recaptchaSiteKey,
                'callback': window.onReCaptchaCallback
            });
        }
    } else {
        setTimeout(window.renderReCaptcha, 500);
    }
}

window.onReCaptchaCallback = function () {
    Livewire.dispatch('captchaResponse', { recaptcha: grecaptcha.getResponse() });
};

window.renderReCaptcha();
