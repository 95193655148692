require("./bootstrap");

/**
 * Import Merchant component
 */
import "./components/merchant";

/**
 * Import and configure Sentry for error tracking
 * Documentation: https://enrollfirst.sentry.io
 */
import * as Sentry from "@sentry/browser";
window.Sentry = Sentry;

/**
 * Swiper - Modern mobile touch slider
 * Documentation: https://www.npmjs.com/package/swiper/v/6.0.0
 */
import Swiper from "swiper";
window.Swiper = Swiper;

/**
 * Alpine.js - Frontend framework for declarative, reactive user interfaces
 * Documentation: https://alpinejs.dev/
 */
import focus from "@alpinejs/focus";
import mask from "@alpinejs/mask";

/**
 * Alpine.js plugins
 */
Alpine.plugin(focus);
Alpine.plugin(mask);

/**
 * Import and configure PDF.js for rendering PDFs
 * Documentation: https://mozilla.github.io/pdf.js/
 */
import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
window.pdfjsLib = pdfjsLib;

/**
 * Tall Toasts - Toast notification library for the Laravel TALL stack
 * Documentation: https://github.com/usernotnull/tall-toasts
 */
import ToastComponent from '../../vendor/usernotnull/tall-toasts/resources/js/tall-toasts';
Alpine.plugin(ToastComponent);

/**
 * Laravel Vapor - Integration for Laravel Vapor
 * Documentation: https://www.npmjs.com/package/laravel-vapor
 */
import Vapor from "laravel-vapor";
window.Vapor = Vapor;

/**
 * Register service worker for Progressive Web App (PWA)
 */
if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("../../service-worker.js");
}
