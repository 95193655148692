window.Components = {

    /**
     * Input Select Dropdown Component
     *
     * This is the updated version of liveSelect() below
     *
     */
    inputSelect(options) {
        return {
            init() {
                this.$refs.listbox.focus();
                this.optionCount = this.$refs.listbox.children.length;
                this.$watch("selected", value => {
                    if (!this.open) return;
                    if (this.selected === null) {
                        this.activeDescendant = "";
                        return;
                    }
                    this.activeDescendant = this.$refs.listbox.children[
                        this.selected - 1
                    ].id;
                });
                this.setOption(this.getOption());
            },
            element: null,
            activeDescendant: null,
            optionCount: null,
            open: false,
            selected: null,
            value: 1,
            setOption(option) {
                this.value = option;
                this.open = false;
            },
            getOption() {
                let input = document.getElementById(this.element + '-select');
                return input ? input.options[input.selectedIndex].getAttribute('key') : false;
            },
            updateOption(option, selectedValue) {
                let input = document.getElementById(this.element + '-select');
                if (input) {
                    input.value = selectedValue;
                    input.dispatchEvent(new Event('change'));
                    this.setOption(option);
                }
            },
            search(value) {
                const options = document.querySelectorAll("." + this.element + "-option");
                for (var i = 0; i < options.length; i++) {
                    const elValue = options[i].dataset.name;
                    if (elValue.toLowerCase().startsWith(value.toLowerCase())) {
                        options[i].classList.remove("hidden");
                    } else {
                        options[i].classList.add("hidden");
                    }
                }
            },
            onButtonClick() {
                if (this.open) return;
                this.selected = this.value;
                this.open = true;
                this.$nextTick(() => {
                    this.$refs.listbox.focus();
                    this.$refs.listbox.children[
                        this.selected - 1
                    ].scrollIntoView({
                        block: "nearest"
                    });
                });
            },
            onOptionSelect() {
                if (this.selected !== null) {
                    this.value = this.selected;
                }
                this.open = false;
                this.$refs.button.focus();
            },
            onEscape() {
                this.open = false;
                this.$refs.button.focus();
            },
            onArrowUp() {
                this.selected =
                    this.selected - 1 < 1
                        ? this.optionCount
                        : this.selected - 1;
                this.$refs.listbox.children[this.selected - 1].scrollIntoView({
                    block: "nearest"
                });
            },
            onArrowDown() {
                this.selected =
                    this.selected + 1 > this.optionCount
                        ? 1
                        : this.selected + 1;
                this.$refs.listbox.children[this.selected - 1].scrollIntoView({
                    block: "nearest"
                });
            },
            ...options
        };
    },


    /**
     * Live Custom Select Menu (unused)
     */
    liveSelect(options) {
        return {
            init() {
                this.$refs.listbox.focus();
                this.optionCount = this.$refs.listbox.children.length;
                this.$watch("selected", value => {
                    if (!this.open) return;
                    if (this.selected === null) {
                        this.activeDescendant = "";
                        return;
                    }
                    this.activeDescendant = this.$refs.listbox.children[
                        this.selected - 1
                    ].id;
                });
            },
            listener: null,
            activeDescendant: null,
            optionCount: null,
            open: false,
            selected: null,
            value: 1,
            choose(option, selectedValue) {
                this.value = option;
                this.open = false;
                window.livewire.dispatch(this.listener, { value: selectedValue });
            },
            onButtonClick() {
                if (this.open) return;
                this.selected = this.value;
                this.open = true;
                this.$nextTick(() => {
                    this.$refs.listbox.focus();
                    this.$refs.listbox.children[
                        this.selected - 1
                    ].scrollIntoView({
                        block: "nearest"
                    });
                });
            },
            onOptionSelect() {
                if (this.selected !== null) {
                    this.value = this.selected;
                }
                this.open = false;
                this.$refs.button.focus();
            },
            onEscape() {
                this.open = false;
                this.$refs.button.focus();
            },
            onArrowUp() {
                this.selected =
                    this.selected - 1 < 1
                        ? this.optionCount
                        : this.selected - 1;
                this.$refs.listbox.children[this.selected - 1].scrollIntoView({
                    block: "nearest"
                });
            },
            onArrowDown() {
                this.selected =
                    this.selected + 1 > this.optionCount
                        ? 1
                        : this.selected + 1;
                this.$refs.listbox.children[this.selected - 1].scrollIntoView({
                    block: "nearest"
                });
            },
            ...options
        };
    }
};
