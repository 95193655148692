document.addEventListener("alpine:init", () => {
    let data = {
        initialized: false,
        initError: false,

        accountid: null,
        fee: null,
        fee_label: "Pay Method",
        defaultPaymethod: "echeck",
        charge_now: false,
        charge_now_amount: null,
        loading: {
            customer: true,
            paymethods: true,
            newmethod: false,
            label: false,
            deleting: false,
            setDefault: false,
            payment: false,
            charge: false,
            post_charge: false,
        },
        paymentReceived: false,
        dropdown: false,
        adding: false,
        new_label: null,
        update_label: false,
        postpone_process_transaction: false,
        allow_custom_draft_date: false,
        default_draft_date: true,
        confirm_default_draft_date: false,
        draft_options: [],
        first_draft_selected: null,
        recurring_draft_selected: null,
        current_recurring_draft_day: null,
        force_draft_today: false,
        last_four: null,
        toggle() {
            if (this.dropdown) {
                return this.close();
            }
            this.dropdown = true;
        },
        close() {
            if (!this.dropdown) return;
            this.dropdown = false;
        },
        updateFees(m) {
            if (m == 'echeck') {
                const echeckTotal = merchant.payment.echeck_total();
                merchant.payment.charge_amount = echeckTotal;
                merchant.payment.fee = merchant.payment.echeck_fee();
                merchant.payment.method_type = "(eCheck)";
                merchant.buttonLabel = "Pay " + merchant.money(echeckTotal);
            } else {
                const cardTotal = merchant.payment.card_total();
                merchant.payment.charge_amount = cardTotal;
                merchant.payment.fee = merchant.payment.card_fee();
                merchant.payment.method_type = "(Credit Card)";
                merchant.buttonLabel = "Pay " + merchant.money(cardTotal);
            }
        },
        money(value) {
            if (window.Intl && typeof Intl.NumberFormat === "function") {

                if (isNaN(value) || value === null || value === undefined) {
                    console.error("Invalid input for money formatting.");
                    return value;
                }

                let formatter = new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                });
                return formatter.format(value);
            } else {
                // Fallback
                return "$" + parseFloat(value).toFixed(2);
            }
        },
        setPaymentMethod(m) {
            merchant.newmethod.method_type = m;
            merchant.updateFees(m);
        },
        getFirstDraftExplanation() {
            const selectedFirstDraft = merchant.draft_options.first_draft_options.find(option => option.value === merchant.first_draft_selected);
            return selectedFirstDraft ? selectedFirstDraft.explanation : '';
        },
        getRecurringDraftExplanation() {
            const selectedRecurringDraft = merchant.draft_options.recurring_draft_options.find(option => option.value === merchant.recurring_draft_selected);
            return selectedRecurringDraft ? selectedRecurringDraft.explanation : '';
        },
        cancelCustomDraftDate() {
            merchant.default_draft_date = true;
            merchant.confirm_default_draft_date = false;
            merchant.setDefaultDraftOptions();
        },
        customer: {
            token: null,
            status: null,
            name: null,
            first_name: null,
            last_name: null,
        },
        checkout: false,
        payment: {
            opportunity: null,
            subtotal: 0.0,
            fee: 0,
            charge_amount: 0,
            method_type: "(eCheck)",
            error: {
                message: null,
                desc: null,
            },
            card_fee() {
                const subtotal = parseFloat(this.subtotal);
                if (isNaN(subtotal)) {
                    console.error("Invalid subtotal for card fee calculation");
                    return 0;
                }

                const fee = 4 + Math.floor((subtotal - 0.01) / 100) * 2;
                return merchant.fee === null ? (fee > 12 ? 12 : fee) : merchant.fee;
            },
            card_total() {
                const fee = this.card_fee();
                const subtotal = parseFloat(this.subtotal);
                if (isNaN(subtotal) || isNaN(fee)) {
                    console.error(
                        "Invalid subtotal or fee for card total calculation"
                    );
                    return 0;
                }
                return parseFloat((subtotal + fee).toFixed(2));
            },
            echeck_fee() {
                const subtotal = parseFloat(this.subtotal);
                if (isNaN(subtotal)) {
                    console.error(
                        "Invalid subtotal for eCheck fee calculation"
                    );
                    return 0;
                }
                return merchant.fee === null ? 4 : merchant.fee;
            },
            echeck_total() {
                const fee = this.echeck_fee();
                const subtotal = parseFloat(this.subtotal);
                if (isNaN(subtotal) || isNaN(fee)) {
                    console.error(
                        "Invalid subtotal or fee for eCheck total calculation"
                    );
                    return 0;
                }
                return parseFloat((subtotal + fee).toFixed(2));
            },
        },
        paymethod: null,
        paymethods: null,
        buttonLabel: "Add to Wallet!",
        canPay() {
            if (
                this.checkout &&
                !this.loading.newmethod &&
                !this.loading.payment &&
                ((this.adding && this.newmethod.method_valid) ||
                    (!this.adding && this.paymethod))
            ) {

                if (!this.default_draft_date && !this.confirm_default_draft_date) {
                    return false;
                }

                return true;
            } else {
                return false;
            }
        },
        charge() {
            if (!merchant.loading.charge && !merchant.paymentReceived) {
                merchant.payment.error.message = null;
                merchant.payment.error.desc = null;

                merchant.loading.charge = true;

                let payload = {};

                payload.default_method = merchant.checkout
                    ? true
                    : merchant.newmethod.default_method;
                payload.save = merchant.newmethod.save;
                payload.adding = merchant.adding;
                payload.customer = merchant.customer;
                payload.opportunity = merchant.payment.opportunity;
                payload.amount = merchant.payment.charge_amount;
                payload.subtotal = merchant.payment.subtotal;
                payload.fee = merchant.payment.fee;
                payload.accountid = merchant.accountid;
                payload.postpone_process_transaction = merchant.postpone_process_transaction;
                payload.default_draft_date = merchant.default_draft_date;

                if (!merchant.default_draft_date) {
                    payload.force_draft_today = merchant.draft_options.force_draft_today;
                    payload.first_draft_selected = merchant.first_draft_selected;
                    payload.recurring_draft_day_selected = merchant.recurring_draft_selected;
                    payload.current_recurring_draft_day = merchant.current_recurring_draft_day;
                }
                if (merchant.adding) {
                    payload.type = merchant.newmethod.method_type;
                    if (merchant.newmethod.method_type == "card") {
                        payload.notes =
                            merchant.newmethod.name_on_card +
                            " - " +
                            merchant.newmethod.card_icon.toUpperCase();
                        payload.payload = {
                            name_on_card: merchant.newmethod.name_on_card,
                            card_type: merchant.newmethod.card_icon,
                            account_number:
                                merchant.newmethod.unformatted_card_number(),
                            expire_month: merchant.newmethod.expire_month,
                            expire_year: merchant.newmethod.expire_year,
                            card_verification_value: merchant.newmethod.cvc,
                        };
                        merchant.last_four = merchant.newmethod.unformatted_card_number().slice(-4);
                        payload.last_four = merchant.last_four;
                    } else {
                        payload.notes = merchant.customer.name + " - eCheck ";
                        payload.payload = {
                            account_holder: merchant.customer.name,
                            account_number: merchant.newmethod.account_number,
                            routing_number: merchant.newmethod.routing_number,
                            account_type: merchant.newmethod.account_type,
                        };
                        merchant.last_four = merchant.newmethod.account_number.slice(-4);
                        payload.last_four = merchant.last_four;
                    }
                } else {
                    payload.type = merchant.paymethod.card ? "card" : "eCheck";
                    payload.notes = null;
                    payload.payload = {
                        paymethod_token: merchant.paymethod.paymethod_token,
                    };
                }

                let config = {
                    method: "post",
                    url: "/merchant/charge",
                    data: payload,
                };

                axios(config)
                    .then((response) => {
                        if (response.data.status == "success") {
                            merchant.loading.post_charge = true;
                            merchant.adding = false;
                            response.data.data.method_used = merchant.payment.method_type;
                            payload = {
                                response: response.data.data,
                                customer: merchant.customer.token
                            };
                            Livewire.dispatch('checkoutResponse', { payload: payload });
                            merchant.paymentReceived = true;
                            merchant.loading.charge = false;
                        } else if (response.data.status == "processing") {
                            setTimeout(() => {
                                location.reload(true);
                            }, 2000);
                        } else if (response.data.status == "fee-error") {
                            console.log('FEE ERROR');
                            console.log(response.data);
                            let error =
                                "System error: Invalid calculated fee amount";
                            merchant.loading.charge = false;
                            merchant.payment.error.message = error;
                            merchant.payment.error.desc =
                                "Please try refreshing the page.";
                            Toast.danger(error, "Error");
                        } else {
                            console.log('ERROR');
                            console.log(response.data);
                            let error = merchant.helpers.getError(
                                response.data.data.response_code
                            );
                            merchant.loading.charge = false;
                            merchant.payment.error.message = error;
                            merchant.payment.error.desc = response.data.data
                                .response_code
                                ? response.data.data.response_code +
                                ": " +
                                response.data.data.message
                                : null;
                            Toast.danger(error, "Error");
                        }
                    })
                    .catch((error) => {
                        console.log("ERROR");
                        console.log(error);
                        Toast.danger("Oops. Something went wrong...", "Error");
                        merchant.payment.error.message =
                            "Oops. Something went wrong... Please confirm the information or refresh the page and try again.";
                        merchant.payment.error.desc = null;
                        if (merchant.checkout) {
                            merchant.loading.charge = false;
                        }
                    });
            }
        },
        newmethod: {
            method_type: "echeck",
            account_type: "checking",
            default_method: true,
            save: true,
            routing_number: null,
            account_number: null,
            account_holder: null,
            name_on_card: null,
            card_number: null,
            cvc: null,
            card_number_error: false,
            expire_month: new Date().getMonth() + 1,
            expire_year: new Date().getFullYear() + 2,
            method_valid: false,
            card_icon: null,
            create_error: null,
            unformatted_card_number() {
                if (merchant.newmethod.card_number) {
                    return merchant.newmethod.card_number.replace(/\s+/g, "");
                }
                return merchant.newmethod.card_number;
            },
            clearForm() {
                merchant.newmethod.method_type = "echeck";
                merchant.newmethod.account_type = "checking";
                merchant.newmethod.default_method = true;
                merchant.newmethod.save = true;
                merchant.newmethod.routing_number = null;
                merchant.newmethod.account_number = null;
                merchant.newmethod.account_holder = null;
                merchant.newmethod.name_on_card = null;
                merchant.newmethod.card_number = null;
                merchant.newmethod.cvc = null;
                merchant.newmethod.card_number_error = false;
                merchant.newmethod.expire_month = new Date().getMonth() + 1;
                merchant.newmethod.expire_year = new Date().getFullYear() + 2;
                merchant.newmethod.method_valid = false;
                merchant.newmethod.card_icon = null;
                merchant.newmethod.create_error = null;
            },
            createPaymethod() {
                merchant.newmethod.create_error = null;
                merchant.loading.newmethod = true;

                let payload = {};

                let set_default = merchant.checkout
                    ? true
                    : merchant.newmethod.default_method;

                payload.default_method = set_default;
                payload.customer = merchant.customer.token;
                payload.type = merchant.newmethod.method_type;

                if (merchant.newmethod.method_type == "card") {
                    payload.notes =
                        merchant.newmethod.name_on_card +
                        " - " +
                        merchant.newmethod.card_icon.toUpperCase();
                    payload.payload = {
                        name_on_card: merchant.newmethod.name_on_card,
                        card_type: merchant.newmethod.card_icon,
                        account_number:
                            merchant.newmethod.unformatted_card_number(),
                        expire_month: merchant.newmethod.expire_month,
                        expire_year: merchant.newmethod.expire_year,
                        card_verification_value: merchant.newmethod.cvc,
                    };
                } else {
                    /** New Bank Method */
                    payload.notes = merchant.customer.name + " - eCheck ";
                    payload.payload = {
                        account_holder: merchant.customer.name,
                        account_number: merchant.newmethod.account_number,
                        routing_number: merchant.newmethod.routing_number,
                        account_type: merchant.newmethod.account_type,
                    };
                }

                let config = {
                    method: "put",
                    url: "/merchant/paymethod",
                    data: payload,
                };


                axios(config)
                    .then((response) => {
                        console.log("ADD RESPONSE");
                        console.log(response.data);
                        if (response.data.status == "success") {
                            merchant.paymethods = response.data.data;
                            merchant.adding = false;
                            merchant.newmethod.clearForm();
                            if (merchant.checkout) {
                                // go charge the card
                                merchant.loading.payment = false;
                            } else {
                                if (merchant.charge_now) {
                                    merchant.charge();
                                } else {
                                    let payload = {
                                        response: response.data.data,
                                        customer: merchant.customer.token
                                    };
                                    Livewire.dispatch('paymethodAdded', { payload: payload });
                                }
                            }
                        } else {
                            if (merchant.checkout) {
                                merchant.loading.payment = false;
                            }
                            Toast.danger(
                                response.data.data.response.response_desc,
                                "Error"
                            );
                        }
                    })
                    .catch((error) => {
                        Toast.danger("Oops. Something went wrong...", "Error");
                        if (merchant.checkout) {
                            merchant.loading.payment = false;
                        }
                        merchant.loading.newmethod = false;
                    })
                    .finally(function () {
                        merchant.loading.newmethod = false;
                    });
            },
            validate() {
                if (
                    merchant.newmethod.method_type == "card" &&
                    merchant.newmethod.name_on_card &&
                    merchant.newmethod.card_icon &&
                    merchant.newmethod.card_number.length == 19 &&
                    merchant.newmethod.expire_year &&
                    merchant.newmethod.expire_month &&
                    merchant.newmethod.cvc &&
                    merchant.newmethod.cvc.length == 3
                ) {
                    return true;
                } else if (
                    merchant.newmethod.method_type == "echeck" &&
                    merchant.newmethod.account_type &&
                    merchant.newmethod.account_number &&
                    merchant.newmethod.account_number.length >= 2 &&
                    merchant.newmethod.routing_number &&
                    merchant.newmethod.routing_number.length >= 2
                ) {
                    return true;
                }
                return false;
            },
        },
        logos: ["checking", "savings", "visa", "mast", "disc"],
        select(method) {
            this.paymethod = method;
            this.adding = false;
            this.toggle();
        },
        add() {
            merchant.adding = true;

            merchant.setPaymentMethod(merchant.defaultPaymethod);

            this.toggle();
        },
        setDefaultDraftOptions() {
            merchant.current_recurring_draft_day = merchant.draft_options.current_recurring_draft_day;
            merchant.force_draft_today = merchant.draft_options.force_draft_today;
            const recurringOption = merchant.draft_options.recurring_draft_options.find(option => option.value == (merchant.current_recurring_draft_day ?? 15));
            merchant.recurring_draft_selected = recurringOption ? recurringOption.value : merchant.draft_options.recurring_draft_options[0].value;
            merchant.first_draft_selected = merchant.draft_options.first_draft_options[0].value;

        },
        setAsDefault() {
            merchant.loading.setDefault = true;

            let config = {
                method: "post",
                url: "/merchant/setDefaultPaymethod",
                data: {
                    customer: merchant.customer.token,
                    token: merchant.paymethod.paymethod_token,
                    type: merchant.paymethod.card ? "Credit Card" : "eCheck",
                },
            };

            axios(config)
                .then((response) => {
                    if (response.data.status == "success") {
                        merchant.paymethods = response.data.data;
                        merchant.paymethod.is_default = true;
                        Toast.success(
                            "Payment Method is set to Default",
                            "Success"
                        );
                    } else {
                        Toast.danger(
                            "Unable to set Payment Method to default",
                            "Error"
                        );
                    }
                })
                .catch((error) => {
                    console.log("ERROR: " + error);
                })
                .finally(function () {
                    merchant.loading.setDefault = false;
                });
        },
        deletePayMethod() {
            merchant.loading.deleting = true;

            let config = {
                method: "delete",
                url: "/merchant/paymethod",
                data: {
                    customer: merchant.customer.token,
                    token: merchant.paymethod.paymethod_token,
                },
            };

            axios(config)
                .then((response) => {
                    if (response.data.status == "success") {
                        merchant.paymethods = response.data.data;
                        merchant.paymethod = merchant.paymethods[0];
                        Toast.success(
                            "Payment Method has been deleted!",
                            "Success"
                        );
                    } else {
                        Toast.danger(
                            "Unable to delete Payment Method",
                            "Error"
                        );
                    }
                })
                .catch((error) => {
                    console.log("ERROR: " + error);
                })
                .finally(function () {
                    merchant.loading.deleting = false;
                });
        },
        updateLabel() {
            merchant.loading.label = true;

            let config = {
                method: "post",
                url: "/merchant/paymethod/updateLabel",
                data: {
                    token: merchant.paymethod.paymethod_token,
                    new_label: merchant.new_label,
                },
            };

            axios(config)
                .then((response) => {
                    if (response.data.status == "success") {
                        merchant.paymethod.label = merchant.new_label;
                        if (merchant.paymethods.length > 0) {
                            merchant.paymethods.forEach((el) => {
                                if (
                                    merchant.paymethod.paymethod_token ==
                                    el.paymethod_token
                                ) {
                                    el.label = merchant.new_label;
                                }
                            });
                        }
                        Toast.success("Label has been updated!", "Success");
                    } else {
                        Toast.danger("Unable to update label", "Error");
                    }
                })
                .catch((error) => {
                    console.log("ERROR: " + error);
                })
                .finally(function () {
                    merchant.new_label = null;
                    merchant.update_label = false;
                    merchant.loading.label = false;
                });
        },
        getCustomer(
            accountid = null,
            amount = null,
            opportunity = null,
            checkout = false,
            defaultPaymethod = null,
            fee = null,
            postpone_process_transaction = false,
            draft_options = null,
        ) {
            let self = this;
            self.payment.subtotal = amount;
            self.payment.opportunity = opportunity;
            self.checkout = checkout;
            self.newmethod.expire_year = new Date().getFullYear() + 1;
            self.accountid = accountid;
            self.postpone_process_transaction = postpone_process_transaction;
            self.draft_options = draft_options;

            // Set default draft values
            if (self.draft_options) {
                merchant.allow_custom_draft_date = true;
                merchant.setDefaultDraftOptions();
            }

            // Set default payment method
            if (defaultPaymethod) {
                merchant.setPaymentMethod(defaultPaymethod);
            }

            // Handle custom fee
            if (fee !== null) {
                self.fee = fee.value;
                self.fee_label = fee.label ?? "Pay Method";
                merchant.updateFees(merchant.newmethod.method_type);
            }

            /**
             * Set Testing Credit Card
             *
             *  This will set the method and credit card fields to a testing credit card. Enable for Local or Staging Only - Be sure to disable for Production
             */
            //self.setTestingCreditCard();

            axios
                .get("/merchant/customer/" + accountid)
                .then(function (response) {
                    const customer = response.data.data;
                    if (customer) {
                        self.customer.token = customer.token;
                        self.customer.name = customer.name;
                        self.customer.first_name = customer.first_name;
                        self.customer.last_name = customer.last_name;
                        self.paymethod = customer.paymethod;
                        self.paymethods = customer.paymethods;
                        if (
                            !customer.paymethod ||
                            !customer.paymethod.paymethod_token
                        ) {
                            merchant.adding = true;
                        }
                        merchant.adding = true;
                    } else {
                        self.initError = true;
                        console.log("Init Error: No customer found");
                    }
                })
                .catch(function (error) {
                    self.initError = true;
                    console.log("Init Error: " + error);
                })
                .finally(function () {
                    self.loading.customer = false;
                });
        },
        setTestingCreditCard() {
            merchant.newmethod.name_on_card = "John Doe";
            merchant.newmethod.card_number = "4111 1111 1111 1111";
            merchant.newmethod.expire_month = "12";
            merchant.newmethod.expire_year = "2029";
            merchant.newmethod.cvc = "123";
            merchant.setPaymentMethod('card');
        },
        helpers: {
            detectCard(card_number) {
                if (card_number && card_number.length > 0) {
                    let first = card_number.substring(0, 1);
                    let two = card_number.substring(0, 2);
                    let four = card_number.substring(0, 4);

                    if (first == 4) {
                        return "visa";
                    } else if (two >= 51 && two <= 55) {
                        return "mast";
                    } else if (two == 65 || four == 6011) {
                        return "disc";
                    }
                }
                return null;
            },
            cardNumberError(card_number, card_icon) {
                if (card_number && card_number.length >= 4 && !card_icon) {
                    return true;
                } else {
                    return false;
                }
            },
            getError(code) {
                let statuses = [
                    {
                        value: "U83",
                        text: "Transaction declined by your financial institution.  Please contact them directly or provide a new payment method and try again.",
                    },
                    {
                        value: "U10",
                        text: "Transaction declined as a temporary caution by the payment gateway, which identified the request as a potential duplicate of another recent transaction.  Please try again in a few minutes.",
                    },
                    {
                        value: "U80",
                        text: "Transaction declined.  The account information could not be validated. Please confirm the information or provide a new payment method and try again.",
                    },
                    {
                        value: "U02",
                        text: "Transaction declined.  The account is not approved. Please confirm the information or provide a new payment method and try again.",
                    },
                    {
                        value: "U19",
                        text: "The routing number provided is invalid. Please confirm the information or provide a new payment method and try again.",
                    },
                    {
                        value: "U84",
                        text: "The transaction could not be completed because the financial institution is not responding. Please wait a few minutes or provide a new payment method and try again.",
                    },
                    {
                        value: "U89",
                        text: "The transaction could not be completed because the financial institution is not responding. Please wait a few minutes or provide a new payment method and try again.",
                    },
                    {
                        value: "U53",
                        text: "The transaction could not be completed at this time. Please confirm the information or provide a new payment method and try again.",
                    },
                ];

                let status = statuses.find((s) => s.value === code);
                if (status) {
                    return status.text;
                } else {
                    return "The transaction could not be completed at this time. Please confirm the information or provide a new payment method and try again.";
                }
            },
        },
    };

    window.merchant = Alpine.reactive(data);
    Alpine.effect(() => {

        /** Credit Card Icon */
        merchant.newmethod.card_icon = merchant.helpers.detectCard(
            merchant.newmethod.card_number
        );

        /** Validate paymethod */
        merchant.newmethod.method_valid = merchant.newmethod.validate();

        /** Validate Card Number */
        merchant.newmethod.card_number_error = merchant.helpers.cardNumberError(
            merchant.newmethod.card_number,
            merchant.newmethod.card_icon
        );

        /** Reset defaults */
        merchant.buttonLabel = "Add to Wallet";

        /** If in Checkout state */
        // if (merchant.checkout) {
        const isEcheckPaymethod =
            (merchant.paymethod && !merchant.adding &&
                merchant.paymethod.paymethod_type === "echeck") || (merchant.adding && merchant.newmethod.method_type === "echeck");

        const isCardPaymethod =
            (merchant.paymethod && !merchant.adding &&
                merchant.paymethod.paymethod_type === "card") || (merchant.adding && merchant.newmethod.method_type === "card");

        /** Handle eCheck Payment */
        if (isEcheckPaymethod) {
            const echeckTotal = merchant.payment.echeck_total();
            merchant.payment.charge_amount = echeckTotal;
            merchant.payment.fee = merchant.payment.echeck_fee();
            merchant.payment.method_type = "(eCheck)";
            merchant.buttonLabel = "Pay " + merchant.money(echeckTotal);
        } else if (isCardPaymethod) {
            /** Handle Card Payment */
            const cardTotal = merchant.payment.card_total();
            merchant.payment.charge_amount = cardTotal;
            merchant.payment.fee = merchant.payment.card_fee();
            merchant.payment.method_type = "(Credit Card)";
            merchant.buttonLabel = "Pay " + merchant.money(cardTotal);
        }
        // }
    });


    document.addEventListener("chargeComplete", function () {
        Toast.success("Payment complete. Thank you.", "Success");
        merchant.loading.charge = false;
    });
});
