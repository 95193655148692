
/**
 * [Portal Logout Event]
 */
window.addEventListener('logout', () => {
    setTimeout(() => {
        window.location.href = '/';
    }, 200);
});

/**
 * [Portal New Notification Sound Event]
 *
 * @return  {[void]}
 */

let originalTitle = document.title;
let newMessageCount = 0;

window.addEventListener("newNotification", () => {
    let audio = new Audio("https://cdn.enrollfirst.com/audio/ding.mp3");
    audio.volume = 0.2;
    audio.play().catch(() => {
        console.error("Failed to play audio");
    });
});

window.addEventListener("clearNotifications", () => {
    document.title = originalTitle;
    newMessageCount = 0;
});

window.addEventListener("setChatNotificationCount", (e) => {
    if (e.detail > 0) {
        newMessageCount = e.detail;
        document.title = `(${newMessageCount}) New Messages - ${originalTitle}`;
    } else {
        document.title = originalTitle;
    }
});

/**
 * Copy text to clipboard
 */
window.copyToClipboard = function (elementId, text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(
        function () { },
        function (err) { }
    );
    let el = document.getElementById(elementId);
    let html = el.innerHTML;
    el.innerHTML = "Copied!";
    setTimeout(function () {
        el.innerHTML = html;
    }, 1500);
};

/**
 * Format Money
 */
window.money = function (value) {
    if (window.Intl && typeof Intl.NumberFormat === "function") {

        if (isNaN(value) || value === null || value === undefined) {
            console.error("Invalid input for money formatting.");
            return value;
        }

        let formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        });
        return formatter.format(value);
    } else {
        // Fallback
        return "$" + parseFloat(value).toFixed(2);
    }
};


/**
 * Captialize First Character
 */
Object.defineProperty(String.prototype, 'capitalize', {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false
});

/**
 * Set Input Focus
 *
 * @return  {[void]}
 */
window.addEventListener("focusInput", event => {
    setTimeout(() => {
        document.getElementById(event.detail).focus();
    }, 300);
});


/**
 * Debug Logging
 */
function logElementJSON(elementId, label) {
    var element = document.getElementById(elementId);
    if (element && element.innerHTML) {
        try {
            var jsonData = JSON.parse(element.innerHTML);
            console.log(label + ':');
            console.log(jsonData);
        } catch (e) {
            console.error('Error parsing JSON from element #' + elementId, e);
        }
    }
}

window.productsDebug = function () {
    logElementJSON('productsDebug', 'Available Products');
};

window.subscriptionsDebug = function () {
    logElementJSON('subscriptionsDebug', 'Subscriptions');
};

window.quoteDebugLog = function () {
    logElementJSON('quoteDebug', 'Quote');
};

window.primaryDebug = function () {
    logElementJSON('primaryDebug', 'Primary');
};

window.portalDebug = function () {
    logElementJSON('portalDebug', 'Portal Session Object');
};

window.saasDebug = function () {
    logElementJSON('saasDebug', 'SaaS Config Object');
};

window.serviceDebug = function () {
    logElementJSON('serviceDebug', 'Service Debug');
};

window.contributionDebug = function () {
    logElementJSON('contributionDebug', 'Contribution Model');
};

window.rateTableDebug = function () {
    logElementJSON('primaryRateDebug', 'Primary Rate variables');
    logElementJSON('spouseRateDebug', 'Spouse Rate variables');
};
window.runDebug = function () {
    logElementJSON('debug', 'Debug');
};
window.portalSessionDebug = function () {
    window.portalDebug();
    window.saasDebug();
};
window.quoteDebug = function () {
    window.primaryDebug();
    window.quoteDebugLog();
    window.subscriptionsDebug();
    window.productsDebug();
    window.portalDebug();
    window.saasDebug();
    window.serviceDebug();
};

function createChart(id, type, labels, dataOrDatasets, label = '') {
    let ctx = document.getElementById(id);
    if (ctx.chart) ctx.chart.destroy();

    const datasets = Array.isArray(dataOrDatasets) && typeof dataOrDatasets[0] === 'object'
        ? dataOrDatasets
        : [{
            label: label,
            data: dataOrDatasets,
            borderWidth: 1
        }];

    ctx.chart = new Chart(ctx, {
        type: type,
        data: {
            labels: labels,
            datasets: datasets
        },
        options: {
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        }
    });
}

window.addEventListener('superChartRefresh', function (e) {
    setTimeout(() => {
        createChart('quoteActivity', 'doughnut', e.detail.quoteActivity.labels, e.detail.quoteActivity.data, '');
        createChart('registrations', 'bar', e.detail.registrations.labels, e.detail.registrations.data, 'Registrations:');
        createChart('importActivity', 'bar', e.detail.importActivity.labels, e.detail.importActivity.data, 'User Imports:');
        createChart('jobInvocations', 'bar', e.detail.jobInvocations.labels, e.detail.jobInvocations.data, 'Job Invocations:');
        createChart('processedCampaignEvents', 'bar', e.detail.processedCampaignEvents.labels, e.detail.processedCampaignEvents.data, 'Campaign Events:');
        createChart('selectedJobInvocations', 'bar', e.detail.selectedJobInvocations.labels, e.detail.selectedJobInvocations.data, 'Job Invocations:');
        const sObjectPollActivity = e.detail.sObjectPollActivity;
        const datasets = [
            {
                label: 'Updates',
                data: sObjectPollActivity.data.map(d => d.updates),
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1
            },
            {
                label: 'Refreshes',
                data: sObjectPollActivity.data.map(d => d.refresh),
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1
            }
        ];

        createChart('sObjectPollActivity', 'bar', sObjectPollActivity.labels, datasets);
    }, 300);
});

/**
 * Adjust slot in layout to adjust to nav and banners
 */
window.adjustSlot = function () {
    const nav = document.getElementById('efi-nav-container');
    const slot = document.querySelector('.efi-slot-container');
    if (nav && slot) {
        slot.style.marginTop = `${nav.offsetHeight}px`;
    }
};
window.onload = window.adjustSlot;
window.onresize = window.adjustSlot;

/**
 * Listener for a browser url redirect
 */
window.addEventListener('browserRedirect', function (e) {
    console.log('Redirecting to:', e.detail.url);
    if (e.detail.url && e.detail.new_window) {
        window.open(e.detail.url, '_blank');
    } else {
        window.location.href = e.detail.url
    }
});

/**
 * Super Audit Log export to PDF
 */
window.exportAuditToPDF = function () {
    const element = document.getElementById('audit-log-container');
    const options = {
        margin: [0.0, 0.0, 0.0, 0.0],
        filename: "{{ $opportunityId }}-audit-log.pdf",
        html2canvas: { scale: 1 },
        jsPDF: { unit: 'in', format: [20, 16], orientation: 'portrait' }
    };

    html2pdf().set(options).from(element).toPdf().get('pdf').then((pdf) => {
        window.open(pdf.output('bloburl'), '_blank');
    }).finally(() => {
        console.log('PDF generated for share');
    });
}

/**
 * Methods for model wrapper in member portal
 */
setModelWrapperTab = function (tab) {
    const icon = document.getElementById(tab + '-icon');
    const loading = document.getElementById(tab + '-icon-loading');
    // hide icon
    icon.style.display = 'none';
    // show loading
    loading.style.display = 'block';
};
