
/**
 * PopperJS - Tooltip & Popup positioning engine
 *
 * Documentation: https://popper.js.org/
 */
import { createPopper } from "@popperjs/core";

window.initTooltip = function (id) {
    const toggleElement = document.querySelector('[data-tooltip-target="' + id + '"]');
    const tooltipElement = document.getElementById(id);
    const placement = tooltipElement.getAttribute("data-tooltip-placement") || "top";
    const trigger = tooltipElement.getAttribute("data-tooltip-trigger") || "hover";
    let hideTimeout;

    const popperInstance = createPopper(toggleElement, tooltipElement, {
        placement: placement,
        modifiers: [{ name: "offset", options: { offset: [0, 8] } }],
    });

    function show() {
        clearTimeout(hideTimeout);
        tooltipElement.classList.remove("opacity-0", "invisible");
        tooltipElement.classList.add("opacity-100", "visible");

        popperInstance.setOptions((options) => ({
            ...options,
            modifiers: [...options.modifiers, { name: "eventListeners", enabled: true }],
        }));
        popperInstance.update();
    }

    function hide() {
        hideTimeout = setTimeout(() => {
            tooltipElement.classList.remove("opacity-100", "visible");
            tooltipElement.classList.add("opacity-0", "invisible");

            popperInstance.setOptions((options) => ({
                ...options,
                modifiers: [...options.modifiers, { name: "eventListeners", enabled: false }],
            }));
        }, 150);
    }

    let showEvents = [];
    let hideEvents = [];

    switch (trigger) {
        case "hover":
            showEvents = ["mouseenter", "focus"];
            hideEvents = ["mouseleave", "blur"];
            break;
        case "click":
            showEvents = ["click", "focus"];
            hideEvents = ["focusout", "blur"];
            break;
        default:
            showEvents = ["mouseenter", "focus"];
            hideEvents = ["mouseleave", "blur"];
    }

    showEvents.forEach((event) => {
        toggleElement.addEventListener(event, show);
    });

    hideEvents.forEach((event) => {
        toggleElement.addEventListener(event, hide);
    });

    tooltipElement.addEventListener("mouseenter", show);
    tooltipElement.addEventListener("mouseleave", hide);
};
